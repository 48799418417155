import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { AppContext } from '_context'
import variables from '_config/css-variables'
import { SwfastPortfolio } from '_templates'

const SwfastCaseStudy = ({ data }) => {
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const content = data.contentfulSwFastCaseStudy

  useEffect(() => {
    setNavbarColor(variables['swfast-primary'])
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  return (
    <SwfastPortfolio
      navbarColorOverride={variables['swfast-primary']}
      pageTitle={content.pageTitle}
      pageDescription={content.pageDescription}
      pageThumbnailUrl={content.pageThumbnail.file.url}
      heroDescription={content.heroDescription.internal.content}
      heroImage={content.heroImage.fluid}
      heroImageAlt={content.heroImage.description}
      challengeTitle={content.challengeTitle}
      challengeDescription={content.challengeDescription.internal.content}
      solutionTitle={content.solutionTitle}
      solutionDescription={content.solutionDescription.internal.content}
      solutionImage={content.solutionImage.fluid}
      solutionImageAlt={content.solutionImage.description}
      whiteLabelTitle={content.whiteLabelTitle}
      whiteLabelDescription={content.whiteLabelDescription.internal.content}
      whiteLabelImages={content.whiteLabelImages}
      deliveryTitle={content.deliveryTitle}
      deliveryDescription={content.deliveryDescription.internal.content}
      deliveryImage={content.deliveryImage.fluid}
      deliveryImageAlt={content.deliveryImage.description}
      takeoutTitle={content.takeoutTitle}
      takeoutDescription={content.takeoutDescription.internal.content}
      takeoutImage={content.takeoutImage.fluid}
      takeoutImageAlt={content.takeoutImage.description}
      scanTitle={content.scanTitle}
      scanDescription={content.scanDescription.internal.content}
      scanImage={content.scanImage.fluid}
      scanImageAlt={content.scanImage.description}
      testimonials={content.testimonials}
      storeImage={content.storeImage.fluid}
      deadlineAndBudgetImage={content.deadlineAndBudgetImage.fluid}
      resultsStoreDescriptionFirstPart={content.resultsStoreDescriptionFirstPart}
      resultsStoreDescriptionSecondPart={content.resultsStoreDescriptionSecondPart}
      resultsStoreDescriptionThirdPart={content.resultsStoreDescriptionThirdPart}
      resultsDeadlineDescriptionFirstPart={content.resultsDeadlineDescriptionFirstPart}
      resultsDeadlineDescriptionSecondPart={content.resultsDeadlineDescriptionSecondPart}
      resultsFirstDescription={content.resultsFirstDescription}
      proudCases={content.proudCases}
      challengeList={content.challengeList}
    />
  )
}

export const query = graphql`
  query SwfastCaseStudyContent($locale: String) {
    contentfulSwFastCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      heroImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      solutionTitle
      solutionDescription {
        internal {
          content
        }
      }
      solutionImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      whiteLabelTitle
      whiteLabelDescription {
        internal {
          content
        }
      }
      whiteLabelImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      whiteLabelImage1 {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      whiteLabelImage2 {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      whiteLabelImage3 {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      deliveryTitle
      deliveryDescription {
        internal {
          content
        }
      }
      deliveryImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      takeoutTitle
      takeoutDescription {
        internal {
          content
        }
      }
      takeoutImage {
        description
        fluid {
          src
          srcSet
        }
      }
      scanTitle
      scanDescription {
        internal {
          content
        }
      }
      scanImage {
        description
        fluid {
          src
          srcSet
        }
      }
      testimonials {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      deadlineAndBudgetImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      storeImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      resultsStoreDescriptionFirstPart
      resultsStoreDescriptionSecondPart
      resultsStoreDescriptionThirdPart
      resultsDeadlineDescriptionFirstPart
      resultsDeadlineDescriptionSecondPart
      challengeList
      workingTogether
      sales
      seriesC
      info
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            src
            srcSet
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

SwfastCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default SwfastCaseStudy
